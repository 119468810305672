.task-list {
  list-style-type: none;
  padding-left: 40px;
}
.breadcrumb-nav {
  float: none;
}
.breadcrumb-nav li {
  display: inline;
  float: none;
}
.breadcrumb-nav li a {
  display: inline;
  padding: 5px 5px 5px 0;
  color: $green;
}
.breadcrumb-nav li:after{
  content: '-';
  display: inline-block;
  padding-right: 5px;
}
.breadcrumb-nav li:last-child:after{
  display: none;
}
.nested {
  font-size: 16px;
  font-style: normal;
  text-align: left;
  margin-bottom: 5px;
}
.nested-name {
  font-style: normal;
  color: #000;
  float: left;
}

.nested-content--notmust {
  display: none;
  margin-left: 50px;
}


.doc-entry-meta {
  margin-bottom: 15px;
}
section {
  .doc-entry-meta--notmust {
    cursor: pointer;
    margin-left: 50px;
    padding: 15px 40px;
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      margin-right: 14px;
      width: 16px;
      height: 16px;
      border: 1px solid rgba(0,0,0,.4);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &.open-checklist {
      &:after {
        content: url("../../images/tick.png");
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1px;
      }
      &:before {
        border-color: $green;
      }
    }

    span:first-of-type {
      border-bottom: 1px dotted;
      font-size: 17px;
    }
    &:hover {
      span:first-of-type {
        border: none;
      }
    }
  }

  nav {
    display: none;
  }
}
#nowdate {
  float: right;
  color: #999;
  font-size: 16px;
  padding: 10px 0;
}
h1 {
  border: none !important;
}
.title-hr {
  margin: 0 0 10px 0;
}

.sidebar-heading {
  border-bottom: none;
  font-size: 24px;

}
.tag-box {
  background-color: transparent;
  padding: 20px 0 0 0;
}
.tag-item {
  padding: 5px 10px 5px 10px;
  margin: 0 5px 0 0;
  background-color: #eee;
  display: inline-block;
}
.main-menu-sidebar {
  padding-top: 10px;
}
.main-menu {
  padding: 30px 0 0 0;
}
.doc-entry-title{
  color: $green !important;
  text-decoration: none;
}
.doc-entry-title:hover, .doc-entry-title:focus {
  text-decoration: none;
}
.main-menu-list {
  padding-left: 0;
  li {
     a {
       color: $green ;
     }
  }
}
.main-menu-list-sec {
  padding-left: 25px;
}
.main-menu-list-third {
  padding-left: 25px;
}
.doc-entry {
  font-size: 18px;
  line-height: 1.2;
  display: block;
  margin: 8px 0;
  color: $green;
  a {
    color: $green;
  }
}
.doc-entry:hover, .doc-entry:focus {
  text-decoration: none;
}
.doc-entry.dir:after {
  content: '>';
  display: inline-block;
  vertical-align: baseline;
  padding-left: 5px;
  transition: transform .2s;
}
.doc-entry.dir.open:after {
  transform: rotate(90deg);
  transition: transform .2s;
}
.doc-entry.dir + .main-menu-list {
  display: none;
}
.doc-entry.dir.open + .main-menu-list {
  display: block;
}
input[type="text"] {
  width: 100%;
  margin-top: 5px;
}
textarea {
  width: 100%;
  //height: 130px;
}
h4 {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
}
p {
  font-size: 16px;
}
label {
  font-size: 16px;
}
em {
  font-size: 14px;
  margin: 0;
  padding: 0;
}
body.print {
  padding: 0;
}
body.print nav{
  display: none;
}
body.print .doc-main {
  width: 100%;
}
body.print .breadcrumb-nav {
  display: none;
}
body.print .doc-sidebar {
  display: none;
}
body.print .print-none {
  display: none;
}
body.print .doc-footer {
  display: none;
}
body.print #js_save {
  display: none;
}
body.print time {
  display: none;
}
body.print .nested {
  display: none;
}
body.print .nested-name {
  display: block;
  float: none;
  text-align: left;
}
body.print .doc-entry-title {
  font-style: normal;
  color: #000;
  font-size: 24px;
  float: left;
}
body.print .doc-footer-em {
  display: none;
}
.doc-footer-em {
  position: fixed;
  padding-top: 30px;
  right: 40px;
  bottom: 40px;
}
#js_save {
  display: inline-block;
  margin-bottom: 40px;
}
main {
  padding-bottom: 40px;
}

body {
  font-family: $mainFont;
  font-weight: 300;
}





h1,h2,h3,h4 {
  color: $green;
  a {
    text-decoration: underline;
    color: $green !important;
    &:hover {
      text-decoration: none;
    }
  }
}
h4 {
  margin-bottom: 6px;
}

h5 {
  font-size: 16px;
}
h6 {
  font-size: 15px;
}

a {
  &:hover {
    text-decoration: underline !important;
  }
}


ul {
  margin: 0 0 50px;
  list-style: none;
  &.main-menu {
    li {
      a {
        color: $green !important;
      }
    }
  }
  li {
    &.active {
      a {
        background: transparent !important;
        box-shadow: none !important;
        &:hover {
          color: $white;
          border-color: $white;
          background: $white !important;
        }
      }
    }
    a {
      color: $green;
    }
  }
  ul {
    margin: 0;
    ul {
      margin: 0;
    }
  }
}

section {
  ul:not(.task-list):not(.radio-wrapper) {
    list-style: circle;
    li {
      margin: 10px 0;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;

      &:before {
        content: '●';
        display: inline-block;
        margin-right: 8px;
        float: left;
        margin-left: -20px;
        color: #c1c1c1;
      }


      &.notbefore {
        &:before {
          display: none;
        }
      }
    }
  }
}

ul {
  li {
    margin: 10px 0;
    label {
      display: inline;
      font-weight: 300;
      cursor: pointer;
      position: relative;
      font-size: 15px;

      input[type="radio"] {
        display: none;
      }
      .check-radio {
        display: inline-block;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        min-width: 20px;
        border: 1px solid rgba(0,0,0, 0.4);
        border-radius: 50%;
        position: relative;
        top: 5px;
      }
      input:checked + .check-radio:before {
        display: block;
        content: '';
        border: 7px solid $green;
        border-radius: 50%;
        top: 2px;
        left: 2px;
        position: absolute;
      }
      input:checked + .check-radio {
        border-color: $green;
      }

      input[type="checkbox"]:checked + span.foo:before {
        display: block;
      }
      input[type="checkbox"]:checked + span.foo {
        border-color: $green;
      }
      span {
        &.foo {
          display: inline-block;
          margin-right: 14px;
          width: 20px;
          height: 20px;
          border: 1px solid rgba($black, 0.4);
          position: relative;
          top: 3px;

          &:before {
            content: url("../../images/tick.png");
            position: absolute;
            display: none;
            top: -9px;
            left: 1px;
          }
        }

      }
    }
  }
}

.star {
  color: red;
}


input[type="checkbox"] {
  display: none;
}


input[type="text"], textarea {
  padding: 8px 10px 8px 34px;
  outline: none;
  border: none;
  border-bottom: 1px dotted $green-dark;
  box-shadow: none;
  font-size: 15px;
}
textarea {
  border: 1px dotted $green;
  margin-top: 10px;
  font-size: 15px;
  //min-height: 130px;
}

.breadcrumb-nav {
  li {
    a {
      font-size: 14px;
      font-style: italic;
      color: $black;
      text-decoration: underline;
      &:hover {
        background: none;
        text-decoration: none !important;
      }
    }
    &.active {
      a {
        text-decoration: none;
        cursor: default;
        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
}

#js_save {
  background: $green;
  color: $white;
  padding: 10px 30px;
  text-transform: uppercase;
  border-radius: 13px;
  &:hover, &:focus, &:active {
    background: $green-dark;
    text-decoration: none !important;
  }
  &:focus, &:active {
     text-decoration: none !important;
   }
}

.doc-footer-em {
  width: 55px;
  height: 55px;
  background: $green;
  cursor: pointer;
  opacity: 0.5;
  a {
    display: block;
    height: 55px;
    top: -67px;
    position: relative;
  }
  &:before {
    content: url("../../images/totop-arrow.png");
    display: block;
    position: relative;
    top: -19px;
    left: 11px;
  }

  &:hover {
    opacity: 1;
  }
}


.doc-entry-section {
  &:before {
    content: '\003E';
  }
}


.txtstuff {
  resize: none; /* remove this if you want the user to be able to resize it in modern browsers */
  overflow: hidden;
}

.hiddendiv {
  display: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word; /* future version of deprecated 'word-wrap' */
}

/* the styles for 'commmon' are applied to both the textarea and the hidden clone */
/* these must be the same for both */
textarea {
  min-height: 130px;
  font-size: 15px;
  overflow: hidden;
  line-height: 18px;
}

.lbr {
  line-height: 3px;
}

.navbar-nav {
  li.nav__instruction {
    position: relative;
    .check-instruction {
      padding: 20px 10px;
      display: none;
      position: absolute;
      top: 100%;
      width: 600px;
      background: white;
      border: 2px solid $green-dark;
      border-radius: 10px;
    }
    a:hover + .check-instruction {
      display: block;
    }
  }
}

.modal {
  .modal-header {
    padding-bottom: 0;
    border: none;
  }
  .modal-body {
    padding: 0 auto 10px;
  }
}

.show-me-baby {
  display: none;
  margin: -20px 0 0 50px;
}


nav ul li {
  &:before {
    display: none !important;
  }
}
