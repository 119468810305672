.sidebar {
  .sidebar-module {
  }
}


.tag-box {
  padding: 0;
  .tag-item {
    margin: 4px 2px;
    background: $white;
    border: 1px dotted $green;
    padding: 2px 20px;
    border-radius: 12px;
    color: $green;
    &:hover {
      text-decoration: none !important;
      background: $green;
      color: $white;
    }
  }
}