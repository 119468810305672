.navbar {
  background: transparent;
  border: none;
  .navbar-collapse {
    display: flex !important;
    align-items: center;
    max-width: 100%;

    .navbar-wrapper-logo {
      background: $green-dark;
      align-self: stretch;
      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative;

      .header-green__triangle {
        display: block;
        width: 45px;
        height: 45px;
        position: absolute;
        left: 150px;
        top: 50%;
        -webkit-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(45deg);
        background-color: $green;
        border-left: 4px solid;
        border-bottom: 4px solid;
        border-color: #fff;
        z-index: 2;
      }
      .navbar-logo {
        a {
          margin: 10px 66px 10px 20px;
          display: block;

        }
        img {
          width: 110px;
        }
      }
    }
    .navbar-wrapper-menu {
      padding: 0 20px;
      width: 100%;
      background: $green;
      align-self: stretch;
      display: flex;
      align-items: center;
      ul {
        li {
          a {
            color: $white;
            border: 1px dotted;
            padding: 6px 20px;
            border-radius: 13px;
            text-shadow: none;
            &:hover {
              text-decoration: none !important;
              border-color: $green;
              background: $white;
              color: $green;
            }
          }
        }
      }
    }
  }
}
