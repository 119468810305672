@charset "UTF-8";
.task-list {
  list-style-type: none;
  padding-left: 40px; }

.breadcrumb-nav {
  float: none; }

.breadcrumb-nav li {
  display: inline;
  float: none; }

.breadcrumb-nav li a {
  display: inline;
  padding: 5px 5px 5px 0;
  color: #55b030; }

.breadcrumb-nav li:after {
  content: '-';
  display: inline-block;
  padding-right: 5px; }

.breadcrumb-nav li:last-child:after {
  display: none; }

.nested {
  font-size: 16px;
  font-style: normal;
  text-align: left;
  margin-bottom: 5px; }

.nested-name {
  font-style: normal;
  color: #000;
  float: left; }

.nested-content--notmust {
  display: none;
  margin-left: 50px; }

.doc-entry-meta {
  margin-bottom: 15px; }

section .doc-entry-meta--notmust {
  cursor: pointer;
  margin-left: 50px;
  padding: 15px 40px;
  position: relative; }
  section .doc-entry-meta--notmust:before {
    content: '';
    display: inline-block;
    margin-right: 14px;
    width: 16px;
    height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
  section .doc-entry-meta--notmust.open-checklist:after {
    content: url("../../images/tick.png");
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1px; }
  section .doc-entry-meta--notmust.open-checklist:before {
    border-color: #55b030; }
  section .doc-entry-meta--notmust span:first-of-type {
    border-bottom: 1px dotted;
    font-size: 17px; }
  section .doc-entry-meta--notmust:hover span:first-of-type {
    border: none; }

section nav {
  display: none; }

#nowdate {
  float: right;
  color: #999;
  font-size: 16px;
  padding: 10px 0; }

h1 {
  border: none !important; }

.title-hr {
  margin: 0 0 10px 0; }

.sidebar-heading {
  border-bottom: none;
  font-size: 24px; }

.tag-box {
  background-color: transparent;
  padding: 20px 0 0 0; }

.tag-item {
  padding: 5px 10px 5px 10px;
  margin: 0 5px 0 0;
  background-color: #eee;
  display: inline-block; }

.main-menu-sidebar {
  padding-top: 10px; }

.main-menu {
  padding: 30px 0 0 0; }

.doc-entry-title {
  color: #55b030 !important;
  text-decoration: none; }

.doc-entry-title:hover, .doc-entry-title:focus {
  text-decoration: none; }

.main-menu-list {
  padding-left: 0; }
  .main-menu-list li a {
    color: #55b030; }

.main-menu-list-sec {
  padding-left: 25px; }

.main-menu-list-third {
  padding-left: 25px; }

.doc-entry {
  font-size: 18px;
  line-height: 1.2;
  display: block;
  margin: 8px 0;
  color: #55b030; }
  .doc-entry a {
    color: #55b030; }

.doc-entry:hover, .doc-entry:focus {
  text-decoration: none; }

.doc-entry.dir:after {
  content: '>';
  display: inline-block;
  vertical-align: baseline;
  padding-left: 5px;
  transition: transform .2s; }

.doc-entry.dir.open:after {
  transform: rotate(90deg);
  transition: transform .2s; }

.doc-entry.dir + .main-menu-list {
  display: none; }

.doc-entry.dir.open + .main-menu-list {
  display: block; }

input[type="text"] {
  width: 100%;
  margin-top: 5px; }

textarea {
  width: 100%; }

h4 {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px; }

p {
  font-size: 16px; }

label {
  font-size: 16px; }

em {
  font-size: 14px;
  margin: 0;
  padding: 0; }

body.print {
  padding: 0; }

body.print nav {
  display: none; }

body.print .doc-main {
  width: 100%; }

body.print .breadcrumb-nav {
  display: none; }

body.print .doc-sidebar {
  display: none; }

body.print .print-none {
  display: none; }

body.print .doc-footer {
  display: none; }

body.print #js_save {
  display: none; }

body.print time {
  display: none; }

body.print .nested {
  display: none; }

body.print .nested-name {
  display: block;
  float: none;
  text-align: left; }

body.print .doc-entry-title {
  font-style: normal;
  color: #000;
  font-size: 24px;
  float: left; }

body.print .doc-footer-em {
  display: none; }

.doc-footer-em {
  position: fixed;
  padding-top: 30px;
  right: 40px;
  bottom: 40px; }

#js_save {
  display: inline-block;
  margin-bottom: 40px; }

main {
  padding-bottom: 40px; }

body {
  font-family: "Lato", sans-serif;
  font-weight: 300; }

h1, h2, h3, h4 {
  color: #55b030; }
  h1 a, h2 a, h3 a, h4 a {
    text-decoration: underline;
    color: #55b030 !important; }
    h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover {
      text-decoration: none; }

h4 {
  margin-bottom: 6px; }

h5 {
  font-size: 16px; }

h6 {
  font-size: 15px; }

a:hover {
  text-decoration: underline !important; }

ul {
  margin: 0 0 50px;
  list-style: none; }
  ul.main-menu li a {
    color: #55b030 !important; }
  ul li.active a {
    background: transparent !important;
    box-shadow: none !important; }
    ul li.active a:hover {
      color: #fff;
      border-color: #fff;
      background: #fff !important; }
  ul li a {
    color: #55b030; }
  ul ul {
    margin: 0; }
    ul ul ul {
      margin: 0; }

section ul:not(.task-list):not(.radio-wrapper) {
  list-style: circle; }
  section ul:not(.task-list):not(.radio-wrapper) li {
    margin: 10px 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px; }
    section ul:not(.task-list):not(.radio-wrapper) li:before {
      content: '●';
      display: inline-block;
      margin-right: 8px;
      float: left;
      margin-left: -20px;
      color: #c1c1c1; }
    section ul:not(.task-list):not(.radio-wrapper) li.notbefore:before {
      display: none; }

ul li {
  margin: 10px 0; }
  ul li label {
    display: inline;
    font-weight: 300;
    cursor: pointer;
    position: relative;
    font-size: 15px; }
    ul li label input[type="radio"] {
      display: none; }
    ul li label .check-radio {
      display: inline-block;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      min-width: 20px;
      border: 1px solid rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      position: relative;
      top: 5px; }
    ul li label input:checked + .check-radio:before {
      display: block;
      content: '';
      border: 7px solid #55b030;
      border-radius: 50%;
      top: 2px;
      left: 2px;
      position: absolute; }
    ul li label input:checked + .check-radio {
      border-color: #55b030; }
    ul li label input[type="checkbox"]:checked + span.foo:before {
      display: block; }
    ul li label input[type="checkbox"]:checked + span.foo {
      border-color: #55b030; }
    ul li label span.foo {
      display: inline-block;
      margin-right: 14px;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(0, 0, 0, 0.4);
      position: relative;
      top: 3px; }
      ul li label span.foo:before {
        content: url("../../images/tick.png");
        position: absolute;
        display: none;
        top: -9px;
        left: 1px; }

.star {
  color: red; }

input[type="checkbox"] {
  display: none; }

input[type="text"], textarea {
  padding: 8px 10px 8px 34px;
  outline: none;
  border: none;
  border-bottom: 1px dotted #0d8500;
  box-shadow: none;
  font-size: 15px; }

textarea {
  border: 1px dotted #55b030;
  margin-top: 10px;
  font-size: 15px; }

.breadcrumb-nav li a {
  font-size: 14px;
  font-style: italic;
  color: #000;
  text-decoration: underline; }
  .breadcrumb-nav li a:hover {
    background: none;
    text-decoration: none !important; }

.breadcrumb-nav li.active a {
  text-decoration: none;
  cursor: default; }
  .breadcrumb-nav li.active a:hover {
    text-decoration: none !important; }

#js_save {
  background: #55b030;
  color: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  border-radius: 13px; }
  #js_save:hover, #js_save:focus, #js_save:active {
    background: #0d8500;
    text-decoration: none !important; }
  #js_save:focus, #js_save:active {
    text-decoration: none !important; }

.doc-footer-em {
  width: 55px;
  height: 55px;
  background: #55b030;
  cursor: pointer;
  opacity: 0.5; }
  .doc-footer-em a {
    display: block;
    height: 55px;
    top: -67px;
    position: relative; }
  .doc-footer-em:before {
    content: url("../../images/totop-arrow.png");
    display: block;
    position: relative;
    top: -19px;
    left: 11px; }
  .doc-footer-em:hover {
    opacity: 1; }

.doc-entry-section:before {
  content: '\003E'; }

.txtstuff {
  resize: none;
  /* remove this if you want the user to be able to resize it in modern browsers */
  overflow: hidden; }

.hiddendiv {
  display: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* future version of deprecated 'word-wrap' */ }

/* the styles for 'commmon' are applied to both the textarea and the hidden clone */
/* these must be the same for both */
textarea {
  min-height: 130px;
  font-size: 15px;
  overflow: hidden;
  line-height: 18px; }

.lbr {
  line-height: 3px; }

.navbar-nav li.nav__instruction {
  position: relative; }
  .navbar-nav li.nav__instruction .check-instruction {
    padding: 20px 10px;
    display: none;
    position: absolute;
    top: 100%;
    width: 600px;
    background: white;
    border: 2px solid #0d8500;
    border-radius: 10px; }
  .navbar-nav li.nav__instruction a:hover + .check-instruction {
    display: block; }

.modal .modal-header {
  padding-bottom: 0;
  border: none; }

.modal .modal-body {
  padding: 0 auto 10px; }

.show-me-baby {
  display: none;
  margin: -20px 0 0 50px; }

nav ul li:before {
  display: none !important; }

.transition-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #55b030;
  z-index: 9999;
  display: flex;
  justify-content: center; }

.transition-loader-inner {
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  width: calc(100% - 200px);
  color: #FFF;
  padding: 0 100px;
  text-align: center; }
  .transition-loader-inner label {
    font-size: 1em;
    padding: 1%;
    opacity: 0;
    display: inline-block; }
    .transition-loader-inner label:after {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 50%; }

.transition-loader-inner label:nth-child(1) {
  -webkit-animation: loader 3s 600ms infinite ease-in-out;
  animation: loader 3s 600ms infinite ease-in-out; }

.transition-loader-inner label:nth-child(2) {
  -webkit-animation: loader 3s 500ms infinite ease-in-out;
  animation: loader 3s 500ms infinite ease-in-out; }

.transition-loader-inner label:nth-child(3) {
  -webkit-animation: loader 3s 400ms infinite ease-in-out;
  animation: loader 3s 400ms infinite ease-in-out; }

.transition-loader-inner label:nth-child(4) {
  -webkit-animation: loader 3s 300ms infinite ease-in-out;
  animation: loader 3s 300ms infinite ease-in-out; }

.transition-loader-inner label:nth-child(5) {
  -webkit-animation: loader 3s 200ms infinite ease-in-out;
  animation: loader 3s 200ms infinite ease-in-out; }

.transition-loader-inner label:nth-child(6) {
  -webkit-animation: loader 3s 100ms infinite ease-in-out;
  animation: loader 3s 100ms infinite ease-in-out; }

@keyframes loader {
  0% {
    opacity: 0;
    transform: translateX(-300px) scale(1); }
  33% {
    opacity: 1;
    transform: translateX(0px) scale(2); }
  66% {
    opacity: 1;
    transform: translateX(0px) scale(1); }
  100% {
    opacity: 0;
    transform: translateX(300px) scale(2); } }

@-webkit-keyframes loader {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-300px); }
  33% {
    opacity: 1;
    -webkit-transform: translateX(0px); }
  66% {
    opacity: 1;
    -webkit-transform: translateX(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(300px); } }

.navbar {
  background: transparent;
  border: none; }
  .navbar .navbar-collapse {
    display: flex !important;
    align-items: center;
    max-width: 100%; }
    .navbar .navbar-collapse .navbar-wrapper-logo {
      background: #0d8500;
      align-self: stretch;
      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative; }
      .navbar .navbar-collapse .navbar-wrapper-logo .header-green__triangle {
        display: block;
        width: 45px;
        height: 45px;
        position: absolute;
        left: 150px;
        top: 50%;
        -webkit-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(45deg);
        background-color: #55b030;
        border-left: 4px solid;
        border-bottom: 4px solid;
        border-color: #fff;
        z-index: 2; }
      .navbar .navbar-collapse .navbar-wrapper-logo .navbar-logo a {
        margin: 10px 66px 10px 20px;
        display: block; }
      .navbar .navbar-collapse .navbar-wrapper-logo .navbar-logo img {
        width: 110px; }
    .navbar .navbar-collapse .navbar-wrapper-menu {
      padding: 0 20px;
      width: 100%;
      background: #55b030;
      align-self: stretch;
      display: flex;
      align-items: center; }
      .navbar .navbar-collapse .navbar-wrapper-menu ul li a {
        color: #fff;
        border: 1px dotted;
        padding: 6px 20px;
        border-radius: 13px;
        text-shadow: none; }
        .navbar .navbar-collapse .navbar-wrapper-menu ul li a:hover {
          text-decoration: none !important;
          border-color: #55b030;
          background: #fff;
          color: #55b030; }

.tag-box {
  padding: 0; }
  .tag-box .tag-item {
    margin: 4px 2px;
    background: #fff;
    border: 1px dotted #55b030;
    padding: 2px 20px;
    border-radius: 12px;
    color: #55b030; }
    .tag-box .tag-item:hover {
      text-decoration: none !important;
      background: #55b030;
      color: #fff; }
